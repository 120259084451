import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from "../store/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/user/login',
    name: 'login',
    component: () => import('../views/User/Login.vue')
  },
  {
    path: '/user/logout',
    name: 'logout',
    component: () => import('../views/User/Logout.vue')
  },
  {
    path: '/user/forget',
    name: 'forget',
    component: () => import('../views/User/Forget.vue')
  },
  {
    path: '/user/refine',
    name: 'refine',
    component: () => import('../views/User/Refine.vue')
  },
  
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Inspect/Index.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/Inspect/Main.vue')
  },
  {
    path: '/main2',
    name: 'main2',
    component: () => import('../views/Inspect/Main2.vue')
  },
  {
    path: '/report',
    name: 'report', 
    component: () => import('../views/Inspect/Report.vue')
  },
  {
    path: '/create',
    name: 'create',
    component: () => import('../views/Inspect/Create.vue')
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/Feedback.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Inspect/Detail.vue'),
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/Inspect/List.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/Inspect/Upload.vue')
  },{
    path: '/Chart',
    name: 'Chart',
    component: () => import('../views/Inspect/Chart.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const token = localStorage.getItem('token');
  if (token) {
	const is_change_pswd = localStorage.getItem('is_change_pswd');
	const is_organ_check = localStorage.getItem('is_organ_check');
	const role_id = localStorage.getItem('role');
	if (is_change_pswd == 0) {
	  to.path === '/user/forget' ? next() : next('/user/forget')
	  NProgress.done();
	}
	if (is_organ_check == 0 && role_id == 4) {
	  to.path === '/user/refine' ? next() : next('/user/refine')
	  NProgress.done();
	}
    to.path === '/user/login' ? next('/') : next()
    NProgress.done();
  } else {
    to.path === '/user/login' ? next() : next('/user/login')
    NProgress.done();
  }
})

export default router
